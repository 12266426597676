// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopImage from "../components/topImage"
import { RenderAst } from "../components/styledComponents"

export default function ObiectiveleStatutare({ data }) {
  const { htmlAst, frontmatter, topImage } = data.markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <TopImage
        topImage={topImage.childImageSharp.gatsbyImageData}
        title={frontmatter.title}
      />
      <div className="container mx-auto flex flex-wrap justify-center w-9/12 pt-10">
        {RenderAst(htmlAst)}
      </div>
    </Layout>
  )
}

export const obiectiveleStatutare = graphql`
  query ObiectiveleStatutare {
    markdownRemark(fileAbsolutePath: { regex: "/obiectiveleStatutare/" }) {
      htmlAst
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
